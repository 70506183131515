<template>
  <v-autocomplete
    v-model="localValue"
    :items="items"
    :loading="searching"
    :search-input.sync="search"
    item-text="full_name"
    hide-no-data
    item-value="uid"
    label="Rechercher un patient"
    no-filter
  >
    <template v-slot:item="{ item, on, attrs }">
      <v-list-item class="patient-autocomplete" transition="slide-y-transition" v-bind="attrs" v-on="on">
        <v-list-item-avatar size="50">
          <user-avatar-img :user="item" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.full_name }}</v-list-item-title>
          <v-list-item-subtitle>
            <span class="secondary--text text--darken-1 font-weight-medium">{{ item.email }}</span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="fs-80">
            {{ $dayjs(item.birth_date).format('DD MMMM YYYY') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import {debounce} from 'throttle-debounce'
  import {empty} from '@/utils/variables'
  import {fromNonRetardToRetardFormat} from '@/utils/date'

  export default {
    name: 'PatientAutocomplete',
    components:{UserAvatarImg},
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        searching: false,
        items: this.value ? [this.value] : [],
        search: null
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value ? this.value.uid : null
        },
        set(value) {
          this.$emit('input', this.items.find(item => item.uid === value))
        }
      }
    },
    watch: {
      search: debounce(300, function (val) {
        if (empty(val) || (this.value && val === this.value.full_name)) return
        this.searchPatients(val)
      })

    },
    methods: {
      formatDate(date) {
        return fromNonRetardToRetardFormat(date, 'date')
      },
      searchPatients(search) {
        this.searching = true
        this.$store.dispatch('patients/searchPatients', {filters: {search_term: search}})
          .then((items) => {
            this.items = items
          })
          .finally(() => {
            this.searching = false
          })
      },
      create(patient) {
        this.items.unshift(patient)
        this.localValue = patient
      }
    }
  }
</script>
