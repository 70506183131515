<template>
  <v-card class="p-rel">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>Inviter un patient</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar title="Ajouter un patient" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <form-category title="Ajouter un patient" subtitle="Veuillez sélectionner un patient à ajouter"
                       class="mt-4"
        />
        <patient-autocomplete v-model="patient" />
        <v-card-actions>
          <v-spacer />
          <v-btn x-small class="mx-1" @click="$emit('close')">Annuler</v-btn>
          <v-btn color="secondary" x-small class="mx-1" :disabled="!patient || submitting" :loading="submitting"
                 @click="submit"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {add as addMember} from '@/modules/spaces/api/members'
  import {Membership, Space} from '@/models'
  import PatientAutocomplete from '@/modules/patients/components/PatientAutocomplete'

  export default {
    name: 'SpaceAddPatientModal',
    components: {PatientAutocomplete, FormCategory, ModalsSidebar},
    data() {
      return {
        patient: null,
        submitting: false
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    methods: {
      async submit() {
        this.submitting = true
        try {
          let {data} = await addMember(this.$store.state.core.space, this.patient.uid)
          Membership.save(data)
          this.space.$mutate(space => {
            ++space.total_members
          })

          this.$root.$emit('space:add_member', data.uid)

          this.$emit('close')
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Patient ajouté',
            subtitle: 'Le patient a été ajouté aux membres de l\'espace.'
          })

        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>
